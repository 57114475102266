window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu.js'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search.js'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('slick-carousel');
